<template>
  <section class="bando-head">
    <h1>Liste des FSE</h1>
  </section>

  <div class="container">
    <section class="content">
      <h2 class="title">Rechercher un FSE</h2>

      <!-- SEARCH -->
      <div class="bloc-search bloc-shadow">
        <form>
          <div class="row">
            <div class="col col-md-9">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control"
                  name="fse"
                  id="fse"
                  placeholder="Ex : Martine PICARD"
                />
              </div>
            </div>
            <!-- <div class="col col-md-3 text-right">
              <button type="submit" class="btn btn-primary">Rechercher</button>
            </div> -->
          </div>
        </form>
      </div>

      <!-- TABLE -->
      <table
        id="table-rdv"
        class="table table-striped bloc-shadow dt-responsive"
      >
        <thead>
          <tr>
            <th></th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Etablissement</th>
            <th>Zones de vacation</th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </section>
  </div>
</template>

<script>
const $ = window.$;

export default {
  name: "VacationListe",
  components: {},
  data() {
    return {
      fses: [],
      zonesVacations: [],
      error: false,
      loading: false
    };
  },
  mounted() {
    const dataTable = $("#table-rdv").DataTable({
      dom: "<<t>r<lip>>",
      columnDefs: [
        {
          orderable: false,
          targets: 0
        }
      ],
      ajax: {
        url: process.env.VUE_APP_API + "/fse/list",
        dataSrc: json => json,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-token")
        }
      },
      processing: true,
      serverSide: false,
      columns: [
        {
          data: "uuid",
          render: data =>
            '<a href="/fiche-fse" class="edit-placeholder" data-v-46b88f30="" id="' +
            data +
            '"><i class="fal fa-eye" data-v-46b88f30="" aria-hidden="true"></i></a>'
        },
        {
          data: "nom",
          className: "text-uppercase"
        },
        {
          data: "prenom"
        },
        {
          data: "uuid",
          className: "text-uppercase",
          render: () => "LABEGE PDC1"
        },
        {
          data: "zones_vacations",
          render: data => {
            console.log(data);
            const str = [];
            data.forEach(zv => {
              str.push('<span class="zone">' + zv.nom + "</span>");
            });
            return str.join("");
          },
          className: "text-uppercase"
        }
      ],
      order: [[1, "asc"]],
      pageLength: 10,
      language: {
        lengthMenu: "Par page : _MENU_",
        zeroRecords: "Aucun résultat",
        processing: "Chargement en cours...",
        paginate: {
          first: "Premier",
          last: "Dernier",
          next: "Suivant",
          previous: "Précédent"
        }
      },
      responsive: true
    });
    $(".dataTables_length select").addClass("custom-select");
    $("#fse").keyup(() => {
      dataTable.search($("#fse").val()).draw();
    });

    const self = this;
    $("tbody", this.$refs.table).on("click", ".edit-placeholder", function(e) {
      e.preventDefault();
      self.$router.push("/fiche-fse/" + $(this).attr("id"));
    });
  }
};
</script>

<style lang="scss">
.content {
  .zone {
    display: block;
    background: var(--navy);
    color: white;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    margin: 0.5rem 0;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
  }
}
</style>
